<template>
  <div id="movie_video"></div>
</template>

<script>
export default {
  watch: {
    route(val) {
      if (!this.showAd) this.startAd()
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      vid: '3495fe56f01f74575a27bdfc25ed1636',
      showAd: false,
    }
  },
  mounted() {
    if (!this.$device.isDesktop) {
      setTimeout(() => {
        this.startAd()
      }, 10 * 1000)
    } else {
      this.startAd()
    }
  },
  methods: {
    checkMA() {
      const chNavElem = document.querySelector('.mobile .ch-nav')
      let findBlock = 0
      
      document.body.querySelectorAll('div').forEach(el => {
        if (el.style.zIndex === '2147483647') {
          // console.log(el.children)

          findBlock = 1

          if (chNavElem) {
            if (el.children[1] && el.children[1].style.opacity === '1') {
              // console.log(el.children[1].style.opacity)

              chNavElem.style.bottom = 31 + 'vh'
              
            } else {
              chNavElem.style.bottom = 0 + 'px'
            }
          }
        }
      })

      if (!findBlock) {
        if (chNavElem) {
          chNavElem.style.bottom = 0 + 'px'
        }
      }
    },
    startAd() {
      if (this.showAd) return
      this.showAd = true
      const script = document.createElement('script')
      script.src = `https://vak345.com/s.js?v=${this.vid}`
      script.setAttribute('async', 'true')
      document.body.appendChild(script)

      const vm = this

      if (!this.$device.isDesktop) {
        setInterval(() => vm.checkMA(), 1000)
      }
    }
  }
}
</script>
